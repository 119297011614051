import React, {Component} from 'react';
import "../assets/css/hint.css";
import {Button} from "react-bootstrap";

class Hint extends Component {

    click = () => {
        this.props.onClick();
    }

    render() {
        let showModal = this.props.show;

        return (
            <div onClick={this.click} className={`hint-modal ${
                showModal ? null : "hidden"
            }`}>
                <h1>
                    Klicke auf das Geschenk, das besser passt. <br/><br/>
                    Wir schlagen dir weitere ähnliche Geschenke vor!</h1>

                <div className="text-center">
                    <Button
                        variant="primary"
                        size="lg"
                        className="card-footer-btn result-btn"
                    >
                        weiter &#8594;
                    </Button>
                </div>

                {/*<img src={swipe} alt="Hint animated image" />*/}
            </div>
        )
    }
}

export default Hint;