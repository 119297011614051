import React, { Component } from 'react';
import '../../assets/css/multistep.css';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWrench, faTheaterMasks, faPlug, faUsers} from "@fortawesome/free-solid-svg-icons";

const cookies = new Cookies();
let restored = 0;

class FifthStep extends Component {

    constructor() {
        super();
        this.state = {
            category: []
        }
    }

    componentDidMount() {
        this.restoreSelection();
    }

    saveAndContinue = (e) => {
        e.preventDefault();

        if(restored > 0) this.props.unblacklistFilter("category");
        this.props.nextStep(this.state.category, "art");
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    restoreSelection = () => {
        let question_history = (cookies.get('question_history') !== undefined) && (cookies.get('question_history') !== null) ?
            Object.entries(cookies.get('question_history')) : [];

        let restoredCategory = [];

        question_history.forEach(element => {

            if (element[0] === "art") {

                let values = element[1];
                let counter = 0;

                values.forEach(val => {

                    counter++;
                    document.getElementById('btn-' + val.split('-')[0]).classList.add('tile-active');
                    //document.getElementById('ico-' + val.split('-')[0]).classList.add('tile-icon-active');
                    document.getElementById('btn-' + val.split('-')[0]).classList.add('tile-check-selected');
                    restoredCategory.push(val);
                });

                //if (counter > 0) document.getElementById("type-button").innerHTML = "speichern";

                restored = counter;
            }
        });

        this.setState({ category: restoredCategory });
    }

    updateButton = () => {
        return false;
        if(this.state.category.length < 1) document.getElementById("type-button").innerHTML = "überspringen";
        if(this.state.category.length >= 1 || restored > 0) document.getElementById("type-button").innerHTML = "speichern";
    }

    toggleCategory = (val) => {

        if ($('#btn-' + val).hasClass('tile-active')) {

            this.setState({ category: this.state.category.filter(function(e) {
                return e !== val;
                })} , () => this.updateButton()
            );
        }
        else {
            this.setState({ category: this.state.category.concat(val) }, () => this.updateButton());
        }
    }

    render() {
        let nextButton = '';

        if (this.props.bestBtn && !this.props.editMode) {

            nextButton = <span className="question-next arrow-btn pulse-button"
                               onClick={this.props.closeFunction}>

                             <span className="arrow-icon">&#8594;</span>

                        </span>;
        }
        else {

            nextButton = <span id="type-button"
                               className="question-next arrow-btn"
                               onClick={this.saveAndContinue}>

                                <span className="arrow-icon">&#8594;</span>

                        </span>;
        }

        return(
            <div>

                <p className="question-title">Welche Art von Geschenk würdest du gerne machen?</p>

                <div className="tile-container row">

                    <div className="tile-set col">

                        <button title="practical"
                                id="btn-practical"
                                onClick={ () => {this.toggleCategory('practical')}}
                                className={`tile check-override ${this.state.category.includes('practical') 
                                    ? "tile-active tile-check-selected" 
                                    : "" }`}>

                        </button>

                        <label htmlFor="btn-practical" className="tile-description">Etwas Praktisches</label>

                    </div>

                    <div className="tile-set col">

                        <button title="funny"
                                id="btn-funny"
                                onClick={ () => {this.toggleCategory('funny')}}
                                className={`tile check-override ${this.state.category.includes('funny') 
                                    ? "tile-active tile-check-selected" 
                                    : "" }`}>

                        </button>

                        <label htmlFor="btn-funny" className="tile-description">Etwas Lustiges</label>

                    </div>

                    <div className="tile-container row">

                        <div className="tile-set col">

                            <button title="motivational"
                                    id="btn-motivational"
                                    onClick={ () => {this.toggleCategory('motivational')}}
                                    className={`tile check-override ${this.state.category.includes('motivational')
                                        ? "tile-active tile-check-selected"
                                        : "" }`}>

                            </button>

                            <label htmlFor="btn-motivational" className="tile-description">Etwas Motivierendes</label>

                        </div>

                        <div className="tile-set col">

                            <button title="together"
                                    id="btn-together"
                                    onClick={ () => {this.toggleCategory('together')}}
                                    className={`tile check-override ${this.state.category.includes('together')
                                        ? "tile-active tile-check-selected"
                                        : "" }`}>

                            </button>

                            <label htmlFor="btn-together" className="tile-description">Etwas für gemeinesame Aktivitäten</label>

                        </div>

                    </div>

                </div>

                <br />

                <div className="bottom">

                    <div id="modal-nav" className="row modal-nav">

                            <span className="question-back arrow-btn" onClick={this.back}>

                                <span className="arrow-icon">&#8592;</span>

                            </span>

                        <div className="steps">

                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step active"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>

                        </div>

                        {nextButton}

                    </div>

                </div>

            </div>
        );
    }
}

export default FifthStep;