import React, { Component } from 'react';
import '../../assets/css/multistep.css';
import Cookies from 'universal-cookie';
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChild, faUserTie, faUserGraduate, faUsers, faBlind} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

const cookies = new Cookies();
let restored = 0;

class SecondStep extends Component {

    constructor() {
        super();
        this.state = {
            age: undefined
        }
    }

    componentDidMount() {
        this.restoreSelection();
    }

    saveAndContinue = () => {
        if(restored > 0) this.props.unblacklistFilter("age");
        this.props.nextStep(this.state.age, "alter");
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    restoreSelection = () => {
        let question_history = (cookies.get('question_history') !== undefined) && (cookies.get('question_history') !== null) ?
            Object.entries(cookies.get('question_history')) : [];

        question_history.forEach(element => {

            if (element[0] === "alter") {

                restored = 1;
                let tile = document.getElementById("btn-" + element[1]);
                let tile_icon = document.getElementById("ico-" + element[1]);

                tile.classList.add("tile-restored");
                tile_icon.classList.add("tile-icon-active");

                //document.getElementById("age-button").innerHTML = "speichern";

                this.setState({ age: element[1]});
            }
        });
    }

    toggleAge = (val) => {
        let iconEl = document.getElementById("ico-" + val);

        if(!iconEl.classList.contains("tile-icon-active")) {

            this.setState({ age: val }, () => this.updateButton());
        }
        else {

            this.setState({ age: undefined }, () => this.updateButton());
        }
    }

    updateButton = () => {

        return false;

        if (this.state.age !== undefined || restored > 0) {
            document.getElementById("age-button").innerHTML = "speichern";
        }
        else {
            document.getElementById("age-button").innerHTML = "überspringen";
        }
    }

    render() {
        let nextButton = '';

        if (this.props.bestBtn && !this.props.editMode) {

            nextButton = <span className="question-next arrow-btn pulse-button"
                               onClick={this.props.closeFunction}>

                             <span className="arrow-icon">&#8594;</span>

                        </span>;
        }
        else {

            nextButton = <span id="age-button"
                               className="question-next arrow-btn"
                               onClick={this.saveAndContinue}>

                                <span className="arrow-icon">&#8594;</span>

                        </span>;
        }

        return(
            <div>

                <div className="tile-container row">

                    <div className="tile-set col">

                        <button title="0-17"
                                id="btn-0"
                                onClick={() => { this.toggleAge('0')} }
                                className={`tile ${this.state.age === '0' 
                                    ? "tile-active"
                                    : (this.state.age === undefined
                                        ? ""
                                        : "tile-unchecked")
                                }`}>

                            <FontAwesomeIcon id="ico-0"
                                             icon={faChild}
                                             size="3x"
                                             className={`tile-icon ${this.state.age === '0' 
                                                 ? "tile-icon-active"
                                                 : (this.state.age === undefined
                                                     ? ""
                                                     : "tile-icon-unchecked")
                                             }`} />

                        </button>

                        <label htmlFor="btn-0"
                               className={`tile-description ${this.state.age === '0'
                                   ? ""
                                   : (this.state.age === undefined
                                       ? ""
                                       : "tile-description-unchecked")
                               }`}>

                            0-17 Jahre

                        </label>

                    </div>

                    <div className="tile-set col">

                        <button title="18-24"
                                id="btn-18"
                                onClick={() => { this.toggleAge('18')} }
                                className={`tile ${this.state.age === '18' 
                                    ? "tile-active"
                                    : (this.state.age === undefined
                                        ? ""
                                        : "tile-unchecked")
                                }`}>

                            <FontAwesomeIcon id="ico-18"
                                             icon={faUserGraduate}
                                             size="3x"
                                             className={`tile-icon ${this.state.age === '18' 
                                                 ? "tile-icon-active"
                                                 : (this.state.age === undefined
                                                     ? ""
                                                     : "tile-icon-unchecked")
                                             }`} />

                        </button>

                        <label htmlFor="btn-18"
                               className={`tile-description ${this.state.age === '18'
                                   ? ""
                                   : (this.state.age === undefined
                                       ? ""
                                       : "tile-description-unchecked")
                               }`}>

                            18-24 Jahre

                        </label>

                    </div>

                    <div className="tile-set col">

                        <button title="25-39"
                                id="btn-25"
                                onClick={() => { this.toggleAge('25')} }
                                className={`tile ${this.state.age === '25' 
                                    ? "tile-active"
                                    : (this.state.age === undefined
                                        ? ""
                                        : "tile-unchecked")
                                }`}>

                            <FontAwesomeIcon
                                id="ico-25"
                                icon={faUserTie}
                                size="3x"
                                className={`tile-icon ${this.state.age === '25' 
                                    ? "tile-icon-active"
                                    : (this.state.age === undefined
                                        ? ""
                                        : "tile-icon-unchecked")
                                    }`} />

                        </button>

                        <label htmlFor="btn-25"
                               className={`tile-description ${this.state.age === '25'
                                   ? ""
                                   : (this.state.age === undefined
                                       ? ""
                                       : "tile-description-unchecked")
                               }`}>

                            25-39 Jahre

                        </label>

                    </div>

                </div>

                <div className="tile-container row">

                    <div className="tile-set col">

                        <button title="40-59"
                                id="btn-40"
                                onClick={() => { this.toggleAge('40')} }
                                className={`tile ${this.state.age === '40' 
                                    ? "tile-active"
                                    : (this.state.age === undefined
                                        ? ""
                                        : "tile-unchecked")
                                }`}>

                            <FontAwesomeIcon id="ico-40"
                                             icon={faUsers}
                                             size="3x"
                                             className={`tile-icon ${this.state.age === '40' 
                                                 ? "tile-icon-active"
                                                 : (this.state.age === undefined
                                                     ? ""
                                                     : "tile-icon-unchecked")
                                             }`} />

                        </button>

                        <label htmlFor="btn-40"
                               className={`tile-description ${this.state.age === '40'
                                   ? ""
                                   : (this.state.age === undefined
                                       ? ""
                                       : "tile-description-unchecked")
                               }`}>

                            40-59 Jahre

                        </label>

                    </div>

                    <div className="tile-set col">

                        <button title="60"
                                id="btn-60"
                                onClick={() => { this.toggleAge('60') }}
                                className={`tile ${this.state.age === '60' 
                                    ? "tile-active"
                                    : (this.state.age === undefined
                                        ? ""
                                        : "tile-unchecked")
                                }`}>

                            <FontAwesomeIcon id="ico-60"
                                             icon={faBlind}
                                             size="3x"
                                             className={`tile-icon ${this.state.age === '60' 
                                                 ? "tile-icon-active"
                                                 : (this.state.age === undefined
                                                     ? ""
                                                     : "tile-icon-unchecked")
                                             }`} />

                        </button>

                        <label htmlFor="btn-60"
                               className={`tile-description ${this.state.age === '60'
                                   ? ""
                                   : (this.state.age === undefined
                                    ? ""
                                    : "tile-description-unchecked")
                               }`}>

                            60 oder älter

                        </label>

                    </div>

                </div>

                <br />

                <div className="bottom">

                    <div id="modal-nav" className="row modal-nav">

                            <span className="question-back arrow-btn" onClick={this.back}>

                                <span className="arrow-icon">&#8592;</span>

                            </span>

                        <div className="steps">

                            <span className="step"/>
                            <span className="step active"/>
                            <span className="step"/>
                            <span className="step"/>

{/*                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>*/}

                        </div>

                        {nextButton}

                    </div>

                </div>

            </div>
        );
    }
}

export default SecondStep;