import React, { Component } from 'react';
import '../../assets/css/multistep.css';
import Cookies from 'universal-cookie';
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCandyCane, faBirthdayCake, faHeart} from "@fortawesome/free-solid-svg-icons";
import $ from 'jquery';

const cookies = new Cookies();
let restored = 0;

class FirstStep extends Component {

    constructor() {
        super();
        this.state = {
            event: undefined
        }
    }

    componentDidMount() {
        this.restoreSelection();
    }

    saveAndContinue = () => {
        if(restored > 0) this.props.unblacklistFilter("event");
        this.props.nextStep(this.state.event, "anlass");
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    restoreSelection = () => {
        let question_history = (cookies.get('question_history') !== undefined) && (cookies.get('question_history') !== null) ?
            Object.entries(cookies.get('question_history')) : [];

        question_history.forEach(element => {

            if(element[0] === "anlass") {

                if(element[1] !== undefined && element[1] !== null) {

                    restored = 1;

                    let tile = document.getElementById("btn-" + element[1]);
                    let tile_icon = document.getElementById("ico-" + element[1]);

                    tile.classList.add("tile-restored");
                    tile_icon.classList.add("tile-icon-active");

                    //document.getElementById("event-button").innerHTML = "speichern";

                    this.setState({ event: element[1] });
                }
            }
        });
    }

    toggleEvent = (val) => {
        let iconEl = document.getElementById("ico-" + val);

        if(!iconEl.classList.contains("tile-icon-active")) {

            this.setState({ event: val }, () => this.updateButton());
        }
        else {

            this.setState({ event: undefined }, () => this.updateButton());
        }
    }

    updateButton = () => {

        return false;

        if (this.state.event !== undefined || restored > 0) {
            document.getElementById("event-button").innerHTML = "speichern";
        }
        else {
            document.getElementById("event-button").innerHTML = "überspringen";
        }
    }

    render() {
        let nextButton = '';

        if (this.props.bestBtn && !this.props.editMode) {

            nextButton = <span className="question-next arrow-btn pulse-button"
                               onClick={this.props.closeFunction}>

                             <span className="arrow-icon">&#8594;</span>

                        </span>;
        }
        else {

            nextButton = <span id="event-button"
                               className="question-next arrow-btn"
                               onClick={this.saveAndContinue}>

                                <span className="arrow-icon">&#8594;</span>

                        </span>;
        }

        return(
            <div className="centered-content">

                <div className="tile-container row">

                    <div className="tile-set col">

                        <button title="Weihnachten"
                                id="btn-christmas"
                                onClick={ () => { this.toggleEvent('christmas')} }
                                className={`tile ${this.state.event === 'christmas'
                                    ? "tile-active"
                                    : (this.state.event === undefined
                                        ? ""
                                        : "tile-unchecked")
                                }`}>

                            <FontAwesomeIcon id="ico-christmas"
                                             icon={faCandyCane}
                                             size="3x"
                                             className={`tile-icon ${this.state.event === 'christmas'
                                                 ? "tile-icon-active"
                                                 : (this.state.event === undefined
                                                     ? ""
                                                     : "tile-icon-unchecked")
                                             }`} />

                        </button>

                        <label htmlFor="btn-christmas" className="tile-description">Weihnachten</label>

                    </div>

                    <div className="tile-set col">

                        <button title="Geburtstag"
                                id="btn-birthday"
                                onClick={ () => { this.toggleEvent('birthday')} }
                                className={`tile ${this.state.event === 'birthday'
                                    ? "tile-active"
                                    : (this.state.event === undefined
                                        ? ""
                                        : "tile-unchecked")
                                }`}>

                            <FontAwesomeIcon id="ico-birthday"
                                             icon={faBirthdayCake}
                                             size="3x"
                                             className={`tile-icon ${this.state.event === 'birthday'
                                                 ? "tile-icon-active"
                                                 : (this.state.event === undefined
                                                     ? ""
                                                     : "tile-icon-unchecked")
                                             }`} />

                        </button>

                        <label htmlFor="btn-birthday" className="tile-description">Geburtstag</label>

                    </div>

                    <div className="tile-set col">

                        <button title="Kleines Dankeschön"
                                id="btn-thank-you"
                                onClick={ () => { this.toggleEvent('thank-you')} }
                                className={`tile ${this.state.event === 'thank-you'
                                    ? "tile-active"
                                    : (this.state.event === undefined
                                        ? ""
                                        : "tile-unchecked")
                                }`}>

                            <FontAwesomeIcon id="ico-thank-you"
                                             icon={faHeart}
                                             size="3x"
                                             className={`tile-icon ${this.state.event === 'thank-you'
                                                 ? "tile-icon-active"
                                                 : (this.state.event === undefined
                                                     ? ""
                                                     : "tile-icon-unchecked")
                                             }`} />
                        </button>

                        <label htmlFor="btn-thank-you" className="tile-description">Kleines Dankeschön</label>

                    </div>

                </div>

                <br />

                <div className="bottom">

                    <div id="modal-nav" className="row modal-nav">

                            <span className="question-back arrow-btn bg-white">

                                <span className="arrow-icon"> </span>

                            </span>

                        <div className="steps">

                            <span className="step active"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>

                            {/*<span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>*/}

                        </div>

                        {nextButton}

                    </div>

                </div>

            </div>
        );
    }
}

export default FirstStep;
