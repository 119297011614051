import React, { Component } from 'react';
import '../../assets/css/multistep.css';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import { Button } from 'react-bootstrap';

const cookies = new Cookies();

class FirstStep extends Component {

    componentDidMount() {
        this.restoreSelection();
    }

    startQuestions = (e) => {
        e.preventDefault();
        this.props.nextStep();
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    restoreSelection = () => {
        let saved_list = cookies.get('present_save_list');
        let history_list = cookies.get('history_list');
        let question_history = cookies.get('question_history');
        let black_list = cookies.get('black_list');

        if(saved_list === undefined) {
            cookies.set('present_save_list', []);
        }
        if((history_list === undefined) || (question_history === undefined)) {
            cookies.set('history_list', [], { expires: 0 });
            cookies.set('question_history', {}, { expires: 0 });
        }

        if(black_list === undefined) {
            cookies.set('black_list', [], { expires: 0 });
        }

        window.addEventListener(
            "beforeunload",
            function() {
                cookies.remove('question_history');
                cookies.remove('black_list');
            }
        );

        var question_history_object = Object.entries(cookies.get('question_history')), alter = 0;
        question_history_object.forEach(element => {
            if (element[0] === "alter") {
                alter = parseInt(element[1]);
                $("input[name='alter']").val(alter);
            } else {
                var values = element[1];
                if (typeof (values) === "object") {
                    values.forEach(val => {
                        $(":checkbox[value=" + val + "]").prop("checked", "true");
                    });
                }
            }
        });
    }

    render() {
        return(
            <div className="centered-content welcome-msg">
                Bereit, das perfekte Geschenk zu finden?<br/><br/>
                Hilf uns die Person, die du beschenken möchtest, besser kennen zu lernen!

                <div id="modal-nav" className="row modal-nav bottom">
                    <Button variant="primary" className="question-next" onClick={this.startQuestions}>los geht's &#8594;</Button>
                </div>
            </div>
        );
    }
}

export default FirstStep;
