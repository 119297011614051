import React, { Component } from 'react';
import '../../assets/css/multistep.css';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCoins,
    faMoneyBillAlt,
    faSuitcase,
    faWallet,
    faCreditCard
} from "@fortawesome/free-solid-svg-icons";

const cookies = new Cookies();
let restored = 0;

class ThirdStep extends Component {

    constructor() {
        super();
        this.state = {
            price: []
        }
    }

    componentDidMount() {
        this.restoreSelection();
    }

    saveAndContinue = (e) => {
        e.preventDefault();

        if(restored > 0) this.props.unblacklistFilter("budget");
        this.props.nextStep(this.state.price, "kostenpunkt");
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    restoreSelection = () => {
        let question_history = (cookies.get('question_history') !== undefined) && (cookies.get('question_history') !== null) ?
            Object.entries(cookies.get('question_history')) : [];

        let restoredPrice = [];

        question_history.forEach(element => {

            if(element[0] === "kostenpunkt") {

                let values = element[1];
                let counter = 0;

                values.forEach(val => {

                    counter++;
                    document.getElementById('btn-' + val.split('-')[0]).classList.add('tile-active');
                    //document.getElementById('ico-' + val.split('-')[0]).classList.add('tile-icon-active');
                    document.getElementById('btn-' + val.split('-')[0]).classList.add('tile-check-selected');
                    restoredPrice.push(val)
                });

                //if (counter > 0) document.getElementById("price-button").innerHTML = "speichern";

                restored = counter;
            }
        });

        this.setState({ price: restoredPrice });
    }

    updateButton = () => {
        // TODO: enable again if old nav-style is chosen
        return false;
        if(this.state.price.length < 1) document.getElementById("price-button").innerHTML = "überspringen";
        if(this.state.price.length >= 1 || restored > 0) document.getElementById("price-button").innerHTML = "speichern";
    }

    togglePrice = (val, id) => {

        if($('#btn-' + id).hasClass('tile-active')) {

            this.setState({ price: this.state.price.filter(function(e) {
                    return e !== val;
                })} , () => this.updateButton()
            );

        }
        else {

            this.setState({ price: this.state.price.concat(val) }, () => this.updateButton());
        }
    }

    render() {
        let nextButton = '';

        if (this.props.bestBtn && !this.props.editMode) {

            nextButton = <span className="question-next arrow-btn pulse-button"
                               onClick={this.props.closeFunction}>

                             <span className="arrow-icon">&#8594;</span>

                        </span>;
        }
        else {

            nextButton = <span id="price-button"
                               className="question-next arrow-btn"
                               onClick={this.saveAndContinue}>

                                <span className="arrow-icon">&#8594;</span>

                        </span>;
        }

        return(
            <div>

                    <div className="tile-container row">

                        <div className="tile-set col">

                            <button title="5-15"
                                 id="btn-5"
                                 onClick={ () => {this.togglePrice('5-15', '5')}}
                                 className={`tile check-override ${this.state.price.includes('5-15')
                                     ? "tile-active tile-check-selected"
                                     : "" /* :(this.state.price.length === 0
                                         ? ""
                                         : "tile-unchecked")*/
                                 }`}>

                            </button>

                            <label htmlFor="btn-5" className={`tile-description ${this.state.price.includes('5-15')
                                ? ""
                                : "" /* :(this.state.price.length === 0
                                    ? ""
                                    : "tile-description-unchecked")*/
                            }`}>
                                5–15&nbsp;€</label>

                        </div>

                        <div className="tile-set col">

                            <button title="15-30"
                                 id="btn-15"
                                 onClick={ () => {this.togglePrice('15-30','15')}}
                                 className={`tile check-override ${this.state.price.includes('15-30')
                                     ? "tile-active tile-check-selected"
                                     : "" /* :(this.state.price.length === 0
                                         ? ""
                                         : "tile-unchecked")*/
                                 }`}>

                            </button>

                            <label htmlFor="btn-15" className={`tile-description ${this.state.price.includes('15-30')
                                ? ""
                                : "" /* :(this.state.price.length === 0
                                    ? ""
                                    : "tile-description-unchecked")*/
                            }`}>
                                15–30&nbsp;€</label>

                        </div>

                        <div className="tile-set col">

                            <button title="30-50"
                                 id="btn-30"
                                 onClick={ () => {this.togglePrice('30-50','30')}}
                                 className={`tile check-override ${this.state.price.includes('30-50')
                                     ? "tile-active tile-check-selected"
                                     : "" /* :(this.state.price.length === 0
                                         ? ""
                                         : "tile-unchecked")*/
                                 }`}>

                            </button>

                            <label htmlFor="btn-30" className={`tile-description ${this.state.price.includes('30-50')
                                ? ""
                                : "" /* :(this.state.price.length === 0
                                    ? ""
                                    : "tile-description-unchecked")*/
                            }`}>
                                30–50&nbsp;€</label>

                        </div>

                    </div>

                    <div className="tile-container row">

                        <div className="tile-set col">

                            <button title="50-75"
                                 id="btn-50"
                                 onClick={ () => {this.togglePrice('50-75','50')}}
                                 className={`tile check-override ${this.state.price.includes('50-75')
                                     ? "tile-active tile-check-selected"
                                     : "" /* :(this.state.price.length === 0
                                         ? ""
                                         : "tile-unchecked")*/
                                 }`}>

                            </button>

                            <label htmlFor="btn-50" className={`tile-description ${this.state.price.includes('50-75')
                                ? ""
                                : "" /* :(this.state.price.length === 0
                                    ? ""
                                    : "tile-description-unchecked")*/
                            }`}>
                                50–75&nbsp;€</label>

                        </div>

                        <div className="tile-set col">

                            <button title="75"
                                 id="btn-75"
                                 onClick={ () => {this.togglePrice('75', '75')}}
                                 className={`tile check-override ${this.state.price.includes('75')
                                     ? "tile-active tile-check-selected" : ""
                                     /*: (this.state.price.length === 0
                                         ? ""
                                         : "tile-unchecked")*/
                                 }`}>

                            </button>

                            <label htmlFor="btn-75" className={`tile-description ${this.state.price.includes('75')
                                ? "" : ""
                                /*: (this.state.price.length === 0 
                                    ? ""
                                    : "tile-description-unchecked")*/
                            }`}>
                                75&nbsp;€ oder mehr
                            </label>

                        </div>

                    </div>

                    <br />

                    <div className="bottom">

                        <div id="modal-nav" className="row modal-nav">

                            <span className="question-back arrow-btn" onClick={this.back}>

                                <span className="arrow-icon">&#8592;</span>

                            </span>

                            <div className="steps">

                                <span className="step"/>
                                <span className="step"/>
                                <span className="step active"/>
                                <span className="step"/>

                                {/*<span className="step"/>
                                <span className="step"/>
                                <span className="step"/>
                                <span className="step"/>
                                <span className="step"/>
                                <span className="step"/>
                                <span className="step"/>
                                <span className="step"/>
                                <span className="step"/>
                                <span className="step"/>*/}

                            </div>

                            {nextButton}

                        </div>

                    </div>
            </div>
        );
    }
}

export default ThirdStep;