import React, { Component } from 'react';
import '../../../assets/css/multistep.css';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import { Button } from 'react-bootstrap';

const cookies = new Cookies();
let restored = 0;
let last = '';

class TenthStep extends Component {

    componentDidMount() {
        this.restoreSelection();
        $("input[name='q-5']").click(function(event) {
            if(last === $(this).val()) {
                $(this).prop('checked', false);
                last = '';
                return;
            }
            last = $(this).val();
        });

    }

    saveAndContinue = (e) => {
        e.preventDefault()

        let q;
        $.each($("input[name='q-5']:checked"), function(){
            q = $(this)[0];
        });

        q = (q !== undefined) ? q : document.getElementsByName("q-default")[0];

        this.props.nextStep(q);
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    restoreSelection = () => {
        let question_history = Object.entries(cookies.get('question_history'));
        question_history.forEach(element => {
            let val = element[1];
            let key = element[0];
            if(typeof(val) === "string") {
                let el = $(":radio[name='" + key + "'][value='" + val + "']");
                el.prop("checked","true");
                //if (el.length > 0) document.getElementById("q5-button").innerHTML = "speichern";
                restored = el.length;
            }
        });
    }

    updateButton = () => {
        return false;
        if(restored > 0) return false;
        document.getElementById("q5-button").innerHTML = "weiter";
    }


    render() {
        let nextButton = '';

        if (this.props.bestBtn && !this.props.editMode) {

            nextButton = <span className="question-next arrow-btn pulse-button"
                               onClick={this.props.closeFunction}>

                             <span className="arrow-icon">&#8594;</span>

                        </span>;
        }
        else {

            nextButton = <span id="q5-button"
                               className="question-next arrow-btn"
                               onClick={this.saveAndContinue}>

                                <span className="arrow-icon">&#8594;</span>

                        </span>;
        }
        
        return(
            <div onClick={this.updateButton}>

                <p className="question-title">Bei Diskussionen ist der Person wichtig, dass...</p>

                <input className="hidden" type="radio" id="q-5" name="q-default"/>

                <label className="radiolbl">
                    sie Recht bekommt
                    <input type="radio" id="q-5" name="q-5" value="0,100,0,0,0,0,0" />
                    <span className="checkmark" />
                </label>

                <label className="radiolbl">
                    man logisch argumentiert
                    <input type="radio" id="q-5" name="q-5" value="0,0,0,50,50,0,0" />
                    <span className="checkmark" />
                </label>

                <label className="radiolbl">
                    man eine andere Sichtweise einnehmen kann
                    <input type="radio" id="q-5" name="q-5" value="0,0,0,0,0,0,100" />
                    <span className="checkmark" />
                </label>

                <label className="radiolbl">
                    niemand verärgert wird
                    <input type="radio" id="q-5" name="q-5" value="0,0,0,0,100,0,0" />
                    <span className="checkmark" />
                </label>

                <br />

                <div className="bottom">

                    <div id="modal-nav" className="row modal-nav">

                            <span className="question-back arrow-btn" onClick={this.back}>

                                <span className="arrow-icon">&#8592;</span>

                            </span>

                        <div className="steps">

                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step active"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>

                        </div>

                        {nextButton}

                    </div>

                </div>

            </div>
        );
    }
}

export default TenthStep;