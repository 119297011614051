import React, { Component } from 'react';
import '../assets/css/navigation.css';
import {Button, Navbar, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUndo, faSlidersH, faEllipsisH, faBookmark } from "@fortawesome/free-solid-svg-icons";
import { BiUndo } from 'react-icons/bi';

class Navigation extends Component {

    constructor() {
        super();

        this.handlePinClick = this.handlePinClick.bind(this);
        this.handleSettingsClick = this.handleSettingsClick.bind(this);
    }

    handlePinClick() {
        this.props.showPinModal();
    }

    handleSettingsClick() {
        this.props.showSettingsModal();
    }

    getPinButton() {
        return (
            <div>
                <FontAwesomeIcon icon={faBookmark} size="2x" />
                <span id="pinned-count" className="badge badge-secondary">{this.props.pinnedCount}</span>
            </div>
        );
    }

    getPinLoader() {
        return <div className="loader"/>;
    }

    render() {
        let button;
        if(this.props.showLoader) {
            button = this.getPinLoader()
        } else {
            button = this.getPinButton()
        }

        return (
            <Navbar fixed="bottom" expanded="true">
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="p-sm-1 m-auto row">
                        <div className="button-container">
                            <Button variant="primary" id="undo-icon" className="undo-btn nav-icon-bi" onClick={this.props.undo}>
                                {/*<FontAwesomeIcon icon={faUndo} size="2x" />*/}
                                <BiUndo size="3em" />
                            </Button>
                            {/*<p className="nav-description">Rückgängig</p>*/}
                        </div>
                        <div className="button-container">
                            <Button variant="primary" id="open-modal-icon" className="pf-main" onClick={this.props.showQuestionModal}>
                                <FontAwesomeIcon icon={faSlidersH} size="2x" />
                            </Button>
                            {/*<p className="nav-description">Filter</p>*/}
                        </div>
                        <div className="button-container">
                            <Button variant="primary" id="pin-list-icon" className="pin-list" onClick={this.handlePinClick}>
                                {button}
                            </Button>
                            {/*<p className="nav-description">Merkliste</p>*/}
                        </div>
                        <div className="button-container">
                            <Button variant="primary" id="settings-icon" className="settings-btn" onClick={this.handleSettingsClick}>
                                <FontAwesomeIcon icon={faEllipsisH} size="2x" />
                            </Button>
                            {/*<p className="nav-description"> </p>*/}
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Navigation;