import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLink, faBookmark} from '@fortawesome/free-solid-svg-icons';
import {faBookmark as faRegBookmark} from '@fortawesome/free-regular-svg-icons';
import Cookies from 'universal-cookie';
import '../assets/css/card.css';
import {Button} from 'react-bootstrap';
import logo from '../assets/img/logo.png';
import ReactGA from "react-ga4";

const cookies = new Cookies();
const MAX_SHIFT_X = 100;
const MAX_SHIFT_Y = 100;
const altImg = "http://via.placeholder.com/250";

class Card extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            position: "relative",
            top: "auto",
            left: "auto",
            offsetX: 0,
            offsetY: 0,
            startX: 0,
            startY: 0,
            showResultModal: true,
            shifts: [0, 0],
            lastItem: null,
            img: null,
            device: null,
            imgLoaded: false
        };

        this.onSwipeMove = this.onSwipeMove.bind(this);
        this.onSwipeEnd = this.onSwipeEnd.bind(this);
        this.onSwipeStart = this.onSwipeStart.bind(this);

        this.setImgAsLoaded = this.setImgAsLoaded.bind(this);
    }

    setImgAsLoaded(bool) {
        if(bool !== true && bool !== false) bool = true;
        this.setState({imgLoaded: bool});
    }

    diff = (a, b) => a > b ? a - b : b - a

    componentDidMount() {
        this._isMounted = true;
        let img = (document.getElementById(this.props.name)).getElementsByTagName('img')[0], image = new Image();
        image = img;
        image.onerror = function (e) {
            console.error("[ERROR] Failed to load an image, using alernative image instead.");
            e.target.src = altImg;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.imgurl !== this.props.imgurl) {this.setImgAsLoaded(false)}
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    prepareResults = (altItem = null) => {

        let lastItem = [this.props.itemid, this.props.title, this.props.imgurl, this.props.description, this.props.url, this.props.price];

        if (altItem !== null) {
            lastItem = [this.props.otherItem.item_id, this.props.otherItem.item_name, this.props.otherItem.item_thumbnail, this.props.otherItem.item_description, this.props.otherItem.item_url, this.props.otherItem.item_price];
        }

        this.setState({
            position: this.state.position,
            top: this.state.top,
            left: this.state.left,
            offsetX: this.state.offsetX,
            offsetY: this.state.offsetY,
            startX: this.state.startX,
            startY: this.state.startY,
            showResultModal: this.state.showResultModal,
            shifts: this.state.shifts,
            lastItem: lastItem
        });

        const cookies = new Cookies();
        cookies.set('lastItem', lastItem[0]);

        if ((this.state.showResultModal === true) && (this._isMounted)) {
            this.props.showResultModal();
        }
    }

    onSwipeStart(event) {

        var touch = false;

        if (this.props.name === "card_1") {
            let original = document.getElementById(this.props.name);
            let secondCard = document.getElementById("card_2");
            let clone = original.cloneNode(true);
            clone.id = this.props.name + '_clone';
            clone.classList.add("hidden");
            original.parentNode.insertBefore(clone, secondCard);
        }

        document.getElementById(this.props.name).classList.remove("flyin-left");
        document.getElementById(this.props.name).classList.remove("flyin-right");
        document.getElementById(this.props.switchWith).classList.remove("flyin-left");
        document.getElementById(this.props.switchWith).classList.remove("flyin-right");

        let positionX = event.clientX;
        let positionY = event.clientY;

        if ((positionX === undefined) || (positionY === undefined)) {
            //console.log("TOUCH DETECTED", event.touches);
            touch = true;
            positionX = event.touches[0].clientX;
            positionY = event.touches[0].clientY;
        }

        //console.log("Position der Maus", positionX, positionY);

        let box = document.getElementById("card-front-container-" + this.props.name);
        let rect = box.getBoundingClientRect();
        //let body = document.body.getBoundingClientRect();

        //console.log("Position der Karte", rect.left, rect.top);
        //console.log("Position des Bodies", body.left, body.top);

        let offsetX = rect.left;
        let offsetY = rect.top;

        offsetX = positionX - offsetX;
        if(!touch) offsetX = offsetX + rect.width;
        offsetY = positionY - offsetY;

        //console.log("Offsets", offsetX, offsetY);

        this.setState({
            position: this.state.position,
            top: this.state.top,
            left: this.state.left,
            offsetX: offsetX,
            offsetY: offsetY,
            startX: positionX,
            startY: positionY,
            showResultModal: this.state.showResultModal,
            shifts: this.state.shifts,
            lastItem: this.state.lastItem
        });
    }

    onSwipeMove(position, event) {

        if (this.props.name === "card_1") {
            const clone = document.getElementById(this.props.name + '_clone');

            if (clone !== undefined && clone !== null) {

                if (!clone.classList.contains("hidden-card")) {
                    clone.classList.add("hidden-card");
                }

                if (clone.classList.contains("hidden")) {
                    clone.classList.remove("hidden");
                }
            }
        }

        //this.props.showArea();

        let positionX = event.clientX;
        let positionY = event.clientY;

        if ((positionX === undefined) || (positionY === undefined)) {
            positionX = event.touches[0].clientX;
            positionY = event.touches[0].clientY;
        }

        //console.log("Swiping... Shift:", "Event Position", "X: " + position.x, "Y: " + position.y, "Client Position", "X: " + positionX, "Y: " + positionY);

        this.setState({
            position: "absolute",
            top: positionY - this.state.offsetY,
            left: positionX - this.state.offsetX,
            offsetX: this.state.offsetX,
            offsetY: this.state.offsetY,
            startX: this.state.startX,
            startY: this.state.startY,
            showResultModal: this.state.showResultModal,
            shifts: [position.x, position.y],
            lastItem: this.state.lastItem
        });

        return true;
    }

      onSwipeEnd(event) {

        if (this.props.statisticsTracking) {
            ReactGA.event({
                category: 'card',
                action: 'swipe-card'
            });
        }

        let current_device;
        if(event.srcElement !== undefined) current_device = "mouse"; else current_device = "touch";

        let progress_indicator = cookies.get('progress_indicator');
        let progress_last = progress_indicator[1];

        let nodeName = (event.srcElement !== undefined) ? event.srcElement.nodeName.toUpperCase() : event.target.nodeName.toUpperCase();
        let shiftX = this.state.shifts[0];
        let shiftY = this.state.shifts[1];

        //console.log("Shift:", shiftX, shiftY);

        document.getElementById(this.props.switchWith).style.opacity = 1;

        if ((nodeName !== "PATH") && (nodeName !== "A") && (nodeName !== "BUTTON")) {
            if (!((current_device !== this.state.device) && (this.state.device !== null))) {
                if ((shiftX !== 0) || (shiftY !== 0)) {
                    // Card was moved
                    if ((this.diff(0, shiftX) > MAX_SHIFT_X) || (this.diff(0, shiftY) > MAX_SHIFT_Y)) {
                        // If card was moved far enough
                        //if (this.props.currentRound % (this.props.maxRounds + 1) === 0 && this.props.currentRound >= this.props.maxRounds) {
                        if((this.props.currentProgress === this.props.maxRounds) && (this.props.name === progress_last)) {
                            // Last Round --> Prepare Results
                            this.prepareResults(this.props.otherItem);
                        } else {
                            // Card was swiped --> "Click" other card
                            this.props.onClick(this.props.switchWith);
                        }
                    }
                } else {
                    // Card was NOT moved --> Real click
                    //if (this.props.currentRound % (this.props.maxRounds + 1) === 0 && this.props.currentRound >= this.props.maxRounds) {
                    if((this.props.currentProgress === this.props.maxRounds) && (this.props.name === progress_last)) {
                        // Last Round --> Prepare Results
                        this.prepareResults();
                    } else {
                        // Card was clicked --> Trigger click event
                        this.props.onClick(this.props.name);
                    }
                }
            }
        }

        if (this.props.name === "card_1") {
            const clone = document.getElementById(this.props.name + '_clone');

            if (clone !== undefined && clone !== null) {
                clone.remove();
            }
        }

        let device = (this.state.device === null) ? current_device : this.state.device;

        if (this._isMounted) {
                this.setState({
                    position: "relative",
                    top: "auto",
                    left: "auto",
                    offsetX: this.state.offsetX,
                    offsetY: this.state.offsetY,
                    startX: 0,
                    startY: 0,
                    showResultModal: this.state.showResultModal,
                    shifts: [0, 0],
                    lastItem: this.state.lastItem,
                    device: device
                });
        }
      }

    checkForItem = (array, itemid) => {
        let foundItem = false;
        array.map((item) => {
            if ((item.toString() === itemid.toString()) && (foundItem === false)) {
                foundItem = true
            }
        })
        return foundItem;
    }

    pinClick = (event) => {

        if (this.props.statisticsTracking) {
            ReactGA.event({
                category: 'card',
                action: 'pin-item',
                value: this.props.itemid
            });
        }

        event = event || window.event;
        let target = event.target || event.srcElement;
        event.preventDefault();
        let cookies = new Cookies();
        let saved_cookies = cookies.get('present_save_list');


        if (this.checkForItem(saved_cookies, this.props.itemid) === false) {
            /* Add to save list */
            saved_cookies.push(this.props.itemid);
            cookies.set('present_save_list', saved_cookies); target.innerHTML = target.innerHTML.replace('merken', 'gemerkt');
            document.getElementById('bookmark-outline-' + this.props.name).classList.add('hidden');
            document.getElementById('bookmark-filled-' + this.props.name).classList.remove('hidden');
            let el = document.getElementById('pin-list-icon').childNodes[0];
            el.classList.add('scale-up-center');
            el.addEventListener('animationend', () => {
                el.classList.remove('scale-up-center');
            });
        } else {
            /* Remove from save list */
            let index = saved_cookies.indexOf(this.props.itemid);
            if (index > -1) {
                saved_cookies.splice(index, 1);
            } else {
                console.error("Could not find item in save list");
                return false;
            }
            cookies.set('present_save_list', saved_cookies); target.innerHTML = target.innerHTML.replace('gemerkt', 'merken');
            document.getElementById('bookmark-outline-' + this.props.name).classList.remove('hidden');
            document.getElementById('bookmark-filled-' + this.props.name).classList.add('hidden');
        }
        this.props.updatePinned();
    }

    imageError = (e) => {
        console.error("Failed to load an image! Loading alternative image instead...", e.target);
        e.target.src = altImg;
    }

    showShop = () => {
        if (this.props.statisticsTracking) {
            ReactGA.event({
                category: 'card',
                action: 'show-shop',
                value: this.props.itemid
            });
        }
        window.open(this.props.url);
    }

    getImage() {
        let imgLoaded = this.state.imgLoaded;

        return (<div className="column img-column">
                {!imgLoaded && (<div className={"spinner-column"}><div className="spinner-border" role="status">
                    <span className="sr-only">Laden...</span>
                </div></div>)}
                <img
                    className={`card-img embed-responsive-item image-${
                        imgLoaded ? 'visible' :  'hidden'
                    }`}
                    onError={this.imageError}
                    src={this.props.imgurl}
                    alt={this.props.title}
                    id={this.props.name + "_img"}
                    draggable={false}
                    onLoad={()=>{setTimeout(this.setImgAsLoaded, 350)}}
                />

        </div>);
    }

    cardClick = (event) => {

        event = event || window.event;
        let tar = event.target || event.srcElement;

        if(tar.tagName === "BUTTON") {
            return false;
        }

        let el = document.getElementById(this.props.switchWith);
        console.log(el);
        el.style.animation = "flip-card 1.5s";
        el.classList.remove("flip-back");
        el.classList.add("flip");
        el.addEventListener("animationend", function() {
            el.style.animation = "";
            el.classList.remove("flip");
            el.classList.add("flip-back");
        });

        let progress_indicator = cookies.get('progress_indicator');
        let progress_last = progress_indicator[1];

        if ((this.props.currentProgress === this.props.maxRounds) && (this.props.name === progress_last)) {
            // Last Round --> Prepare Results
            this.prepareResults();
        } else {
            // Card was clicked --> Trigger click event
            this.props.onClick(this.props.name);
        }
    }

    render() {

        let cardPosition = this.state.position;
        let cardTop = this.state.top;
        let cardLeft = this.state.left;
        let cardName = this.props.name;
        let cardPinId = "cardpin_" + cardName;
        let cardImg = this.getImage();

        return (
                <div id={this.props.name} className={this.props.className} onClick={this.cardClick}>

                    <div id={`card-front-container-${this.props.name}`} className="card-front card-front--front">

                        <div className="card-title">
                            {this.props.title}
                        </div>

                            <div className="card-body">

                                <div className="row">

                                    {cardImg}

                                </div>

                                <div className="card-menu">

                                    {this.props.price}

                                </div>

                                <div className="row hidden">

                                    <p className="card-description">{this.props.description}</p>

                                </div>

                            </div>

                        <div className="card-footer align-self-baseline">

                            <Button onClick={this.pinClick} id={cardPinId} variant="outline-secondary" size="md"
                                    className="card-footer-btn">
                                <FontAwesomeIcon id={"bookmark-outline-" + cardName} icon={faRegBookmark} size="lg" className="card-footer-icon"/><FontAwesomeIcon id={"bookmark-filled-" + cardName} icon={faBookmark} size="lg" className="card-footer-icon hidden"/> merken
                            </Button>

                            <Button variant="primary" onClick={this.showShop} size="md" className="card-footer-btn">
                                <FontAwesomeIcon icon={faLink} size="lg" className="card-footer-icon"/> zum Produkt
                            </Button>

                        </div>

                    </div>

                    <div className="card-back card-back--back">
                        <img src={logo} width="70%" alt="Logo"/>
                    </div>

            </div>
        )

    }
}

export default Card;