import React, { Component } from 'react';
import '../assets/css/specifity.css';

const specifities = ["zu ungenau", "das geht genauer", "fast geschafft", "perfekt"];

class SpecifityBar extends Component {

    constructor() {
        super();
        this.state = {
            value: 12.5,
            specifity: specifities[0]
        };
    }

    componentDidMount() {
        this.updateSpecifity();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) this.updateSpecifity();
    }

    updateSpecifity = async () => {
        let val = this.props.diffPref;
        let spec = this.state.specifity;
        let best = false;

        if(val !== undefined) {
            switch(true)
            {
                case (val <= 10) && (val >= 0):
                    spec = specifities[3]
                    val = 88;
                    best = true;
                    break;
                case (val <= 20) && (val > 10):
                    spec = specifities[2]
                    val = 63;
                    break;
                case (val <= 30) && (val > 20):
                    spec = specifities[1];
                    val = 38;
                    break;
                case ((val <= 100) && (val > 30)) || (val > 100):
                    spec = specifities[0];
                    val = 13;
                    break;
                default:
                    console.error("Nothing matches", val);
                    break;
            }

            if(val !== parseInt(document.getElementById('specify-pointer').value)) {
                if(val > parseInt(document.getElementById('specify-pointer').value)) {
                    //console.log("goal value is higher than current value");
                    let timer = await setInterval((function () {
                        let pointer = document.getElementById('specify-pointer');

                        if (pointer !== undefined && pointer !== null) {

                            let curr_val = parseInt(pointer.value);
                            if (curr_val < val) {
                                document.getElementById('specify-pointer').value = curr_val + 1;
                            } else {
                                clearInterval(timer);
                                this.setState({value: val, specifity: spec});
                            }
                        }

                    }).bind(this), 10);
                }
                if(val < parseInt(document.getElementById('specify-pointer').value)) {
                    //console.log("goal value is smaller than current value");
                    let timer = await setInterval((function() {
                        let curr_val = parseInt(document.getElementById('specify-pointer').value);
                        if(curr_val > val) {
                            document.getElementById('specify-pointer').value = curr_val - 1;
                        } else {
                            clearInterval(timer);
                            this.setState({value: val, specifity:spec});
                        }
                    }).bind(this),10);
                }
            }
        }
    }

    render() {

            return (<div id="specifity">

                <div id="specifity-title">
                    {this.state.specifity}
                </div>

                { /* <span id="specifity-pointer">
                    <FontAwesomeIcon icon={faAngleDown} />
                </span> */ }

                <input disabled type="range" min="0" max="100" value={this.state.value} className="specify-slider" id="specify-pointer" />

                <div className="specifity-bar" />

            </div>);
    }
}

export default SpecifityBar;