import React, { Component } from 'react';
import '../assets/css/multistep.css';
import Welcome from './steps/welcome';
import FirstStep from './steps/event';
import SecondStep from './steps/age';
import ThirdStep from './steps/price';
import FourthStep from './steps/gender';
import FifthStep from './steps/present_type';
import SixthStep from './steps/questioning/questioning_1';
import SeventhStep from './steps/questioning/questioning_2';
import EighthStep from './steps/questioning/questioning_3';
import NinthStep from './steps/questioning/questioning_4';
import TenthStep from './steps/questioning/questioning_5';
import EleventhStep from './steps/questioning/questioning_6';
import TwelvethStep from './steps/questioning/questioning_7';
import ThirteenthStep from './steps/questioning/questioning_8';
import FourtheenthStep from './steps/questioning/questioning_9';
import Cookies from 'universal-cookie';
import ReactGA from "react-ga4";

const cookies = new Cookies();

class MultiStep extends Component {

    state = {
        step: this.props.startAtStep,
        values: [],
        anlass: '',
        alter: '',
        geschlecht: '',
        art: '',
        kostenpunkt: ''
    };

    welcome = () => {
        const { step } = this.state
        this.setState({
            step : step + 1
        });
        //this.props.showSpecify();
        this.props.showHeader(step + 1);
    }

    collectInformation = (value, field, last = false) => {
        let question_history = cookies.get('question_history');

        const { step } = this.state;

        question_history[field] = value;

        let filters = {
            event: (question_history['anlass'] !== undefined) ? question_history['anlass']: [],
            age: (question_history['alter'] !== undefined) ? question_history['alter']: null,
            gender: (question_history['geschlecht'] !== undefined) ? question_history['geschlecht']: [],
            category: (question_history['art'] !== undefined) ? question_history['art']: [],
            budget: (question_history['kostenpunkt'] !== undefined) ? question_history['kostenpunkt']: []
        };

        this.setState({
            anlass: (question_history['anlass'] !== undefined) ? question_history['anlass']: [],
            alter: (question_history['alter'] !== undefined) ? question_history['alter']: null,
            geschlecht: (question_history['geschlecht'] !== undefined) ? question_history['geschlecht']: [],
            art: (question_history['art'] !== undefined) ? question_history['art']: [],
            kostenpunkt: (question_history['kostenpunkt'] !== undefined) ? question_history['kostenpunkt']: []
        });

        cookies.set('question_history', question_history);

        if (last) {
            this.setState({
                [field]: value
            });
            this.props.closeFunction();
        } else {
            this.setState({
                step : step + 1,
                [field]: value
            });
        }
        this.props.showHeader(step + 1);
        this.props.fetchHandler(filters);
    }

    nextStep = (element, last = false) => {
        if (this.props.statisticsTracking) {
            ReactGA.event({
                category: 'filter',
                action: 'next-step'
            });
        }
        /* Question History */
        let question_history = cookies.get('question_history');
        question_history[element.id] = element.value;
        cookies.set('question_history', question_history);

        /* Grabbing saved type values */
        const { step } = this.state;
        let type_values = [
            (parseInt(element.value.split(",")[0]) || 0),
            (parseInt(element.value.split(",")[1]) || 0),
            (parseInt(element.value.split(",")[2]) || 0),
            (parseInt(element.value.split(",")[3]) || 0),
            (parseInt(element.value.split(",")[4]) || 0),
            (parseInt(element.value.split(",")[5]) || 0),
            (parseInt(element.value.split(",")[6]) || 0)
        ], values = this.state.values;
        values[step-6] = type_values;

        /* Calculating probabilities for filters here */
        let i, j, sum=0, results=[];
        for(j=0;j<7;j++) {
            for(i=0;i<step-5;i++) {
                let prob=parseInt(values[i][j]);
                sum=parseInt(sum+prob);
            }
            results[j] = sum / (step-5);
            sum=0;
        }

        /* Fetch Call */
        let filters = {
            event: this.state.anlass,
            age: this.state.alter,
            gender: this.state.geschlecht,
            category: this.state.art,
            budget: this.state.kostenpunkt,
            a: results[0],
            b: results[1],
            c: results[2],
            d: results[3],
            e: results[4],
            f: results[5],
            g: results[6]
        }
        this.props.fetchHandler(filters);

        if (last) {
            this.setState({step:step, values: values});
            this.props.closeFunction();
        } else {
            this.setState({step:step+1, values: values});
        }
        this.props.showHeader(step + 1);
    }

    prevStep = () => {
        if (this.props.statisticsTracking) {
            ReactGA.event({
                category: 'filter',
                action: 'prev-step'
            });
        }
        const { step } = this.state
        this.setState({
            step : step - 1
        });
        this.props.showHeader(step - 1);
    }

    render() {
        const {step} = this.state;
        let showBestBtn = false;
        // let val = this.props.diffPref;
        // console.log("STEP", step, this.props.showWelcome);
        // if ((val <= 10) && (val > 0)) showBestBtn = true;
        if (step === 4) showBestBtn = true;
        switch(step) {
            case 0:
                if(this.props.showWelcome) {
                    return <Welcome
                            nextStep={this.welcome}
                            skipQuestions={this.props.closeFunction}
                        />
                }
            case 1:
                return <FirstStep
                        nextStep={this.collectInformation}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        unblacklistFilter={this.props.unblacklist}
                        editMode={this.props.editMode}
                        />
            case 2:
                return <SecondStep
                        nextStep={this.collectInformation}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        unblacklistFilter={this.props.unblacklist}
                        editMode={this.props.editMode}
                        />
            case 3:
                return <ThirdStep
                        nextStep={this.collectInformation}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        unblacklistFilter={this.props.unblacklist}
                        editMode={this.props.editMode}
                        />
            case 4:
                return <FourthStep
                        nextStep={this.collectInformation}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        unblacklistFilter={this.props.unblacklist}
                        editMode={this.props.editMode}
                        />
            /*
            case 5:
                return <FifthStep
                        nextStep={this.collectInformation}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        unblacklistFilter={this.props.unblacklist}
                        editMode={this.props.editMode}
                        />
            case 6:
                return <SixthStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            case 7:
                return <SeventhStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            case 8:
                return <EighthStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            case 9:
                return <NinthStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            case 10:
                return <TenthStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            case 11:
                return <EleventhStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            case 12:
                return <TwelvethStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            case 13:
                return <ThirteenthStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            case 14:
                return <FourtheenthStep
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        bestBtn={showBestBtn}
                        closeFunction={this.props.closeFunction}
                        editMode={this.props.editMode}
                        />
            */
            default:
                console.error("Unknown step... Whut?");
                return null;
        }
    }
}

export default MultiStep;