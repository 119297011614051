import React, { Component } from 'react';
import '../assets/css/progress.css';

class ProgressBar extends Component {
    render() {
        return (<div id="progress_bar" className="progress">
            <div id="inner_bar" className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                 style={{width: this.props.value + "%"}}>
            </div>
        </div>);
    }
}

export default ProgressBar;