import React, { Component } from 'react';
import '../../assets/css/multistep.css';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMale, faFemale, faUser} from "@fortawesome/free-solid-svg-icons";

const cookies = new Cookies();
let restored = 0;

class FourthStep extends Component {

    constructor() {
        super();

        this.state = {
            gender: []
        }
    }

    componentDidMount() {
        this.restoreSelection();
    }

    saveAndContinue = (e) => {
        e.preventDefault();

        /*
        $.each($("input[name='geschlecht']:checked"), function(){
            gender.push($(this).val());
        });*/

        if(restored > 0) this.props.unblacklistFilter("gender");
        this.props.nextStep(this.state.gender, "geschlecht", true);
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    restoreSelection = () => {
        let question_history = (cookies.get('question_history') !== undefined) && (cookies.get('question_history') !== null) ?
            Object.entries(cookies.get('question_history')) : [];

        let restoredGender = [];

        question_history.forEach(element => {

            if (element[0] === "geschlecht") {

                let values = element[1];
                let counter = 0;

                values.forEach(val => {

                    counter++;
                    document.getElementById('btn-' + val.split('-')[0]).classList.add('tile-active');
                    //document.getElementById('ico-' + val.split('-')[0]).classList.add('tile-icon-active');
                    document.getElementById('btn-' + val.split('-')[0]).classList.add('tile-check-selected');
                    restoredGender.push(val);
                });

                //if (counter > 0) document.getElementById("gender-button").innerHTML = "speichern";

                restored = counter;
            }
        });

        this.setState({ gender: restoredGender });
    }

    updateButton = () => {
        return false;
        if(this.state.gender.length < 1) document.getElementById("gender-button").innerHTML = "überspringen";
        if(this.state.gender.length >= 1 || restored > 0) document.getElementById("gender-button").innerHTML = "weiter";
    }

    toggleGender = (val) => {

        if ($('#btn-' + val).hasClass('tile-active')) {

            this.setState({ gender: this.state.gender.filter(function(e) {
                    return e !== val;
                })} , () => this.updateButton()
            );
        }
        else {
            this.setState({ gender: this.state.gender.concat(val) }, () => this.updateButton());
        }
    }

    render() {
        let nextButton = '';

        if (this.props.bestBtn && !this.props.editMode) {

            // TODO:    Change onClick back to this.props.closeFunction() iff questioning
            //          steps return back to presentfindr
            nextButton = <span className="question-next arrow-btn pulse-button"
                               onClick={this.saveAndContinue}>

                             <span className="arrow-icon">&#8594;</span>

                        </span>;
        }
        else {

            nextButton = <span id="gender-button"
                                className="question-next arrow-btn"
                                onClick={this.saveAndContinue}>

                                <span className="arrow-icon">&#8594;</span>

                        </span>;
        }

        return(
            <div>

                <div className="tile-container row">

                    <div className="tile-set col">

                        <button title="male"
                                id="btn-male"
                                onClick={ () => { this.toggleGender('male')} }
                                className={`tile check-override ${this.state.gender.includes('male')
                                    ? "tile-active tile-check-selected"
                                    : ""}`}>

                        </button>

                        <label htmlFor="btn-male" className="tile-description">männlich</label>

                    </div>

                    <div className="tile-set col">

                        <button title="female"
                                id="btn-female"
                                onClick={ () => { this.toggleGender('female')} }
                                type="button"
                                className={`tile check-override ${this.state.gender.includes('female')
                                    ? "tile-active tile-check-selected"
                                    : ""}`}>

                        </button>

                        <label htmlFor="btn-female" className="tile-description">weiblich</label>

                    </div>

                    <div className="tile-set col">

                        <button title="diverse"
                                id="btn-diverse"
                                onClick={ () => { this.toggleGender('diverse')} }
                                type="button"
                                className={`tile check-override ${this.state.gender.includes('diverse')
                                    ? "tile-active tile-check-selected"
                                    : ""}`}>

                        </button>

                        <label htmlFor="btn-diverse" className="tile-description">divers</label>

                    </div>

                </div>

                <br />

                <div className="bottom">

                    <div id="modal-nav" className="row modal-nav">

                            <span className="question-back arrow-btn" onClick={this.back}>

                                <span className="arrow-icon">&#8592;</span>

                            </span>

                        <div className="steps">

                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step active"/>

                            {/*<span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>
                            <span className="step"/>*/}

                        </div>

                        {nextButton}

                    </div>

                </div>

            </div>
        );
    }
}

export default FourthStep;