import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/cookiebanner.css';
import './assets/css/showdown.css';
import React, {Component} from 'react';
import ReactGA from 'react-ga4';
import {CookieBanner} from '@etage4/react-cookie-law';
import {Modal, Button} from "react-bootstrap";
import Card from './components/card';
import database from './database.json';
import Cookies from 'universal-cookie';
import MultiStep from "./components/multistep";
import Navigation from "./components/navigation";
import ProgressBar from "./components/progress";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faLink, faUser} from "@fortawesome/free-solid-svg-icons";
import Confetti from 'react-confetti'
import SpecificityBar from "./components/specifity";
import whiteLogo from "./assets/img/logo.png";
import coloredLogo from "./assets/img/logo.color.png";
import Hint from "./components/hint";

let card,
    card2,
    new_card,
    new_card_1,
    new_card_2,
    timer_pointer;

const maxRounds = 100;
const hostname = 'https://presentfindr.com/';
//const hostname = 'http://showdown.local/';
const cookies = new Cookies();
const altImg = "http://via.placeholder.com/250";
const startPreference = 30;

/* Define headers here */
const HEADER_DEFAULT = <img src={whiteLogo} className="modal-logo" alt="Logo presentfindr.com" />;
const HEADER_EVENT = <h2 className="modal-headline">Anlass</h2>;
const HEADER_AGE = <h2 className="modal-headline">Alter</h2>;
const HEADER_PRICE = <h2 className="modal-headline">Preis</h2>;
const HEADER_GENDER = <h2 className="modal-headline">Geschlecht</h2>;
const step_header_map = {0: HEADER_DEFAULT, 1: HEADER_EVENT, 2: HEADER_AGE, 3: HEADER_PRICE, 4: HEADER_GENDER}

class App extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        let saved_cookies = cookies.get('present_save_list');

        this.state = {
            items: [],
            card_1: [],
            card_2: [],
            round: 0,
            filters: {},
            showQuestionModal: true,
            showResultModal: false,
            showPinModal: false,
            showHintModal: false,
            showPinListLoader: false,
            showSettingsModal: false,
            lastItem: [],
            pinnedItems: [],
            listOfItems: saved_cookies,
            runEffect: false,
            effectOpacity: 1,
            progressVal: 0,
            showSpecify: 0,
            marketingTracking: false,
            statisticsTracking: false,
            showBanner: true,
            editFilter: false,
            differencePref: undefined,
            header: HEADER_DEFAULT,
            isFirstLoad: true
        }


        this.handleShowHintModal = this.handleShowHintModal.bind(this);
        this.handleCloseHintModal = this.handleCloseHintModal.bind(this);

        this.handleShowQuestionModal = this.handleShowQuestionModal.bind(this);
        this.handleCloseQuestionModal = this.handleCloseQuestionModal.bind(this);

        this.handleShowPinModal = this.handleShowPinModal.bind(this);
        this.handleClosePinModal = this.handleClosePinModal.bind(this);

        this.handleShowSettingsModal = this.handleShowSettingsModal.bind(this);
        this.handleCloseSettingsModal = this.handleCloseSettingsModal.bind(this);

        this.handleShowResultModal = this.handleShowResultModal.bind(this);
        this.handleCloseResultModal = this.handleCloseResultModal.bind(this);

        this.handleUpdateCards = this.handleUpdateCards.bind(this);

        this.handleDisableEffect = this.handleDisableEffect.bind(this);

        this.toggleBackDrop = this.toggleBackDrop.bind(this);

        this.fetchItems = this.fetchItems.bind(this);

        this.handleCardClick = this.handleCardClick.bind(this);

        this.getPinnedItems = this.getPinnedItems.bind(this);

        this.showSpecifyBar = this.showSpecifyBar.bind(this);
        this.showHeader = this.showHeader.bind(this);
        this.removeConsentGiven = this.removeConsentGiven.bind(this);

        this.doUndo = this.doUndo.bind(this);

        this.removeFilterFromBlacklist = this.removeFilterFromBlacklist.bind(this);
    }

    /* Component related */

    componentDidMount() {

        this._isMounted = true;

        let saved_list = cookies.get('present_save_list');
        let history_list = cookies.get('history_list');
        let question_history = cookies.get('question_history');
        let black_list = cookies.get('black_list');
        let progress_indicator = cookies.get('progress_indicator');
        let filter_history = cookies.get('filter_history');

        if(saved_list === undefined) cookies.set('present_save_list', []);
        if(black_list === undefined) cookies.set('black_list', []);
        if(progress_indicator === undefined) cookies.set('progress_indicator', [0, null]);
        if(filter_history === undefined) cookies.set('filter_history', []);
        if((history_list === undefined) || (question_history === undefined)) {
            cookies.set('history_list', []);
            cookies.set('question_history', {});
        }

        window.addEventListener(
            "beforeunload",
            () => {
                cookies.remove('question_history');
                cookies.remove('black_list');
                cookies.remove('progress_indicator');
                cookies.remove('filter_history');
                cookies.remove('history_list');
                cookies.remove('filter_blacklist');
            }
        );

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);

        /* Grab random products from database in case the user does not select any answers */
        fetch(hostname + database.API_PATH + database.ENDPOINT_UNFILTERED, {credentials: 'omit'})
            .then(res => res.json())
            .then(json => {
                this.handleUpdateCards(json);

            })
            .catch(err => console.log(err));

        this.updatePinned();
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('popstate', this.onBackButtonEvent);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.state !== prevState) console.log("UPDATED");
    }

    /* Handlers */

    handleUpdateCards(json) {

        let card1 = json[Math.floor(Math.random() * json.length)];
        let card2 = json[Math.floor(Math.random() * json.length)];

        while(card1.item_id === card2.item_id) {
            card1 = json[Math.floor(Math.random() * json.length)];
            card2 = json[Math.floor(Math.random() * json.length)];
        }

        this.setState({
            items: json,
            card_1: card1,
            card_2: card2,
            round: this.state.round,
            filters: this.state.filters
        });
    }

    handleShowHintModal() {
        this.setState({showHintModal: true});
    }

    handleCloseHintModal() {
        this.setState({showHintModal: false});
    }

    handleCloseQuestionModal() {
        let { isFirstLoad } = this.state;
        this.handleUpdateCards(this.state.items);
        this.setState({ showQuestionModal: false, header: HEADER_EVENT, isFirstLoad: false, showHintModal: isFirstLoad });
    }

    handleShowQuestionModal() {
        this.setState({ showQuestionModal: true, editFilter: true });
    }

    handleCloseResultModal() {
        this.setState({ round: parseInt(parseInt(this.state.round) + 1), showResultModal: false });
    }

    async handleShowResultModal() {
        if (this.state.statisticsTracking) {
            ReactGA.event({
                category: 'result',
                action: 'show-result'
            });
        }
        let lastItemId = cookies.get('lastItem');
        const response = await fetch(hostname + 'products/' + lastItemId, {credentials: 'omit'});
        cookies.set('progress_indicator', [0, null]);
        const json = await response.json();
        this.setState({ lastItem: json, showResultModal: true, runEffect: true, effectOpacity: 1, progressVal: 0 });
        timer_pointer = setTimeout(() => {
            this.handleDisableEffect();
        }, 3500);
    }

    handleDisableEffect() {
        clearTimeout(timer_pointer);
        timer_pointer = setInterval(() => {
            let op = this.state.effectOpacity;
            let newOp = (op > 0) ? op - 0.1 : 0;
            let newStat = this.state.runEffect;
            if(newOp <= 0) {
                newStat = false;
                clearInterval(timer_pointer);
            }
            this.setState({
                runEffect: newStat,
                effectOpacity: newOp
            });
        }, 100);
    }

    async handleShowPinModal() {
        if (this.state.statisticsTracking) {
            ReactGA.event({
                category: 'navigation',
                action: 'show-pinlist'
            });
        }
        this.setState({ showPinListLoader: true });
        let items = await this.getPinnedItems();
        this.setState({ pinnedItems: items, showPinModal: true, showPinListLoader: false });
    }

    handleClosePinModal() {
        if (this.state.statisticsTracking) {
            ReactGA.event({
                category: 'pinlist',
                action: 'close-pinlist'
            });
        }
        this.setState({ showPinModal: false, showPinListLoader: false });
    }

    handleShowSettingsModal() {
        if (this.state.statisticsTracking) {
            ReactGA.event({
                category: 'navigation',
                action: 'show-settings'
            });
        }
        this.setState({ showSettingsModal: true });
    }

    handleCloseSettingsModal() {
        if (this.state.statisticsTracking) {
            ReactGA.event({
                category: 'settings',
                action: 'close-settings'
            });
        }
        this.setState({ showSettingsModal: false });
    }

    handleCardClick(cardname) {
        if (this.state.statisticsTracking) {
            ReactGA.event({
                category: 'card',
                action: 'click-card'
            });
        }

        Array.prototype.forEach.call(document.getElementsByClassName("nav-description"), function(el) {
            let comp_style = window.getComputedStyle(el);
            if(comp_style.display !== 'none') {
                el.setAttribute('style', 'display:none;');
            }
        });

        let history_list = cookies.get('history_list');
        let black_list = cookies.get('black_list');
        let progress_indicator = cookies.get('progress_indicator');
        let progress_last = progress_indicator[1];
        let progress_val = progress_indicator[0];
        let tries = 0;
        let itemPos = 0;

        progress_val = (progress_last === cardname) ? progress_val + 1 : 0;
        progress_last = cardname;

        cookies.set('progress_indicator', [progress_val, progress_last]);

        progress_val = (progress_val + 1)*20;
        this.setProgressBar(progress_val);

        document.getElementById("card_1").classList.remove("fav");
        document.getElementById("card_2").classList.remove("fav");

        switch(cardname) {
            default:
                document.getElementsByClassName("popup-overlay")[0].style.display = "none";
                card = this.state.card_1;
                card2 = this.state.card_2;
                new_card_1 = this.state.items[Math.floor(Math.random() * this.state.items.length)];
                new_card_2 = this.state.items[Math.floor(Math.random() * this.state.items.length)];

                while((new_card_1.item_id === card.item_id) || (new_card_1.item_id === card2.item_id) || (new_card_2.item_id === card.item_id) || (new_card_2.item_id === card2.item_id)) {
                    if(tries < 5) {
                        new_card_1 = this.state.items[Math.floor(Math.random() * this.state.items.length)];
                        new_card_2 = this.state.items[Math.floor(Math.random() * this.state.items.length)];
                        tries++;
                    } else {
                        break;
                    }
                }

                if(tries >= 5) {
                    console.error("Could not find another item that is not equals to the current existing item...");
                }

                this.setState(
                    {
                        items: this.state.items,
                        card_1: new_card_1,
                        card_2: new_card_2,
                        round: parseInt(this.state.round) + 1,
                        filters: this.state.filters,
                        card_undone: null
                    }
                );
                break;

            case "card_1":

                document.getElementById('bookmark-outline-card_2').classList.remove('hidden');
                document.getElementById('bookmark-filled-card_2').classList.add('hidden');
                document.getElementById("card_1").classList.add("fav");
                document.getElementById("cardpin_card_2").innerHTML = document.getElementById("cardpin_card_2").innerHTML.replace("gemerkt", "merken");

                card = this.state.card_1;
                card2 = this.state.card_2;

                new_card = this.state.items[Math.floor(Math.random() * this.state.items.length)];
                while((new_card.item_id === card.item_id) || (new_card.item_id === card2.item_id) || (this.checkForItem(black_list, new_card.item_id) > -1)) {
                    if(tries < 5) {
                        new_card = this.state.items[Math.floor(Math.random() * this.state.items.length)];
                        tries++;
                    } else {
                        break;
                    }
                }

                if(tries >= 5) {
                    console.error("Could not find another item that is not equals to the current existing item...");
                }

                itemPos = this.checkForItem(history_list, this.state.card_2.item_id);
                if(itemPos === -1) {
                    history_list.push([this.state.card_2.item_id, "c1"]);
                    cookies.set('history_list', history_list, { expires: 0 });
                }

                black_list.push(new_card.item_id)
                cookies.set('black_list', black_list);

                this.setState(
                    {
                        items: this.state.items,
                        card_1: card,
                        card_2: new_card,
                        round: parseInt(this.state.round) + 1,
                        filters: this.state.filters,
                        card_undone: null
                    }
                );

                break;
            case "card_2":

                document.getElementById('bookmark-outline-card_1').classList.remove('hidden');
                document.getElementById('bookmark-filled-card_1').classList.add('hidden');
                document.getElementById("card_2").classList.add("fav");
                document.getElementById("cardpin_card_1").innerHTML = document.getElementById("cardpin_card_1").innerHTML.replace("gemerkt", "merken");

                card = this.state.card_2;
                card2 = this.state.card_1;
                new_card = this.state.items[Math.floor(Math.random() * this.state.items.length)];
                while((new_card.item_id === card.item_id) || (new_card.item_id === card2.item_id) || (this.checkForItem(black_list, new_card.item_id) > -1)) {
                    if(tries < 5) {
                        new_card = this.state.items[Math.floor(Math.random() * this.state.items.length)];
                        tries++;
                    } else {
                        break;
                    }
                }

                if(tries >= 5) {
                    console.error("Could not find another item that is not equals to the current existing item...");
                }

                itemPos = this.checkForItem(history_list, this.state.card_1.item_id); //We have to search for the ejected card
                if(itemPos === -1) {
                    history_list.push([this.state.card_1.item_id, "c2"]);
                    cookies.set('history_list', history_list, { expires: 0 });
                }

                black_list.push(new_card.item_id);
                cookies.set('black_list', black_list);

                this.setState(
                    {
                        items: this.state.items,
                        card_1: new_card,
                        card_2: card,
                        round: parseInt(this.state.round) + 1,
                        filters: this.state.filters,
                        card_undone: null
                    }
                );

                break;

        }
    }

    /* Pinned List */

    async getPinnedItems() {
        let items = cookies.get('present_save_list');
        let urls = []
        items.forEach((el) => {
            urls.push(hostname + database.ENDPOINT_FETCH_PRODUCTS + el);
        });
        const requests = urls.map((url) => fetch(url, {
            method: 'GET',
            credentials: 'omit'
        }));
        const responses = await Promise.all(requests);
        const errors = responses.filter((response) => !response.ok);
        if (errors.length > 0) {
            throw errors.map((response) => Error(response.statusText));
        }
        const json = responses.map((response) => response.json());
        const data = await Promise.all(json);
        let result, jsx;

        if(data.length === 0) {
             jsx = <h1>Du hast noch keine Geschenke gespeichert</h1>;
             result = jsx;
        } else {
             jsx = await data.map((el) => <div key={el.item_id} className="row pinned-items-row">

                <div className="col col-12" data-label="title"><strong>{el.item_name}</strong></div>
                <div className="col col-12" data-label="price">{el.item_price}</div>
                <div className="col col-12" data-label="description">{el.item_description}</div>
                <div className="col col-12" data-label="actions">
                    <Button
                        variant="outline-secondary"
                        onClick={() => { this.trashClick(el.item_id) }}
                        className="deleteBtn card-footer-btn"
                    >
                        <FontAwesomeIcon icon={faTrash} size="lg" className="card-footer-icon" />entfernen
                    </Button>

                    <Button
                        variant="primary"
                        onClick={() => { window.open(el.item_url) }}
                        className="card-footer-btn"
                    >
                        <FontAwesomeIcon icon={faLink} size="lg" className="card-footer-icon" />
                        zum Produkt
                    </Button>

                </div>

            </div>);
             result = await Promise.all(jsx);
        }

        return result;

    }

    updatePinned() {
        let items = cookies.get('present_save_list');
        document.getElementById("pinned-count").innerHTML = items.length;
    }

    countPinned() {
        let items = cookies.get('present_save_list');
        return (items !== undefined && items !== null) ? items.length : 0;
    }

    async trashClick (itemid) {
        if (this.state.statisticsTracking) {
            ReactGA.event({
                category: 'pinlist',
                action: 'trash-pin'
            });
        }
        let saved_cookies = cookies.get('present_save_list');
        saved_cookies = this.removeFromList(saved_cookies, itemid);
        cookies.set("present_save_list", saved_cookies);

        let card_1 = document.getElementById('cardpin_card_1');
        let card_2 = document.getElementById('cardpin_card_2');

        let card_1_item = this.state.card_1.item_id;
        let card_2_item = this.state.card_2.item_id;

        if(itemid === card_1_item) {
            card_1.innerHTML = card_1.innerHTML.replace('gemerkt', 'merken');
            document.getElementById('bookmark-outline-card_1').classList.remove('hidden');
            document.getElementById('bookmark-filled-card_1').classList.add('hidden');
        }
        if(itemid === card_2_item) {
            card_2.innerHTML = card_2.innerHTML.replace('gemerkt', 'merken');
            document.getElementById('bookmark-outline-card_2').classList.remove('hidden');
            document.getElementById('bookmark-filled-card_2').classList.add('hidden');
        }


        let items = await this.getPinnedItems();
        this.setState({ pinnedItems: items, listOfItems: saved_cookies });
    }

    /* Cookie Law Banner */

    toggleBackDrop() {

        var backdropEl = document.getElementById('react-cookie-law-backdrop');

        if (backdropEl === undefined || backdropEl === null) {

            var backdrop = document.createElement('div');
            backdrop.id = 'react-cookie-law-backdrop';
            backdrop.className = 'modal-backdrop fade show';

            //document.getElementById('root').append(backdrop);
        }
        else {
            backdropEl.remove();
        }
    }

    removeConsentGiven() {
        cookies.remove('rcl_consent_given');
        this.setState({showBanner: true});
    }

    /* List Tools */

    removeFromList(array, itemid) {
        return array.filter(function (ele) {
            return ele !== itemid;
        });
    }

    checkForItem(array, itemid) {
        let foundItem = -1;
        let pos = 0;
        array.map((item) => {
            if ((item.toString() === itemid.toString()) && (foundItem === -1)) {
                foundItem = pos;
            }
            pos++;
        })

        return foundItem;
    }

    /* Progressbar Tools */

    setProgressBar(value) {
        if(value >= 0 && value <= 100) {
            this.setState({progressVal: value});
            //let el = document.getElementById('inner_bar');
            let el = document.getElementById('progress_bar');
            //el.classList.add('highlighted-bar');
            el.classList.add('animated-progress');
            el.addEventListener('animationend', () => {
                //el.classList.remove('highlighted-bar');
                el.classList.remove('animated-progress');
            });

        } else { return 0; }
    }

    /* Undo */

    async doUndo() {
        if (this.state.statisticsTracking) {
            ReactGA.event({
                category: 'navigation',
                action: 'undo'
            });
        }
        let history_list = cookies.get('history_list');
        let progress_indicator = cookies.get('progress_indicator');
        let progress_last = progress_indicator[1];
        let progress_val = progress_indicator[0];

        if(history_list.length === 0) return 0;
        let lastCard = history_list.pop();

        progress_val = (progress_val > 0) ? progress_val-1 : 0;
        progress_last = (lastCard[1] === "c1") ? "card_1" : "card_2";

        cookies.set('progress_indicator', [progress_val, progress_last]);

        progress_val = (progress_val > 0) ? (progress_val + 1)*20 : 20;
        this.setProgressBar(progress_val);

        let url = (hostname + database.ENDPOINT_FETCH_PRODUCTS + lastCard[0]);
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'omit'
        }).then((res) => { return res.json() }).then((res) => { return res; });
        let card = {
            "item_id": response.item_id,
            "item_thumbnail": response.item_thumbnail,
            "item_url": response.item_url,
            "item_price": response.item_price,
            "item_description": response.item_description,
            "item_name": response.item_name
        }

        switch(lastCard[1]) {
            case "c2":
                let child_c1 = document.getElementById("card_1");
                child_c1.style.animation = "flip-card 1.5s";
                child_c1.addEventListener("animationend", function() {
                    child_c1.style.animation = "";
                });
                this.setState(
                    {
                        items: this.state.items,
                        card_1: card,
                        card_2: this.state.card_2,
                        round: parseInt(parseInt(this.state.round) - 1),
                        filters: this.state.filters
                    }
                );
                break;
            case "c1":
                let child_c2 = document.getElementById("card_2");
                child_c2.style.animation = "flip-card 1.5s";
                child_c2.addEventListener("animationend", function() {
                    child_c2.style.animation = "";
                });
                this.setState(
                    {
                        items: this.state.items,
                        card_1: this.state.card_1,
                        card_2: card,
                        round: parseInt(parseInt(this.state.round) - 1),
                        filters: this.state.filters
                    }
                );
                break;
            default:
                console.error("A critical error occurred!")
                break;
        }

        cookies.set("history_list", history_list);
    }

    /* Fetching Items */

    blackListFilter(filter) {
        let filter_blacklist = cookies.get('filter_blacklist');
        if(filter_blacklist === undefined) filter_blacklist = [];
        filter_blacklist.push(filter);
        cookies.set('filter_blacklist', filter_blacklist);
    }

    removeFilterFromBlacklist(filter) {
        let filter_blacklist = cookies.get('filter_blacklist');
        if(filter_blacklist === undefined) filter_blacklist = [];
        let index = filter_blacklist.indexOf(filter);
        if (index > -1) {
            filter_blacklist.splice(index, 1);
        }
        cookies.set('filter_blacklist', filter_blacklist);
    }

    diff = function (obj1, obj2) {

        // Make sure an object to compare is provided
        if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
            return obj1;
        }

        let diffs = {};
        let key;

        let arraysMatch = function (arr1, arr2) {

            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false;
            }
            return true;

        };

        let compare = function (item1, item2, key, ref) {

            let type1 = Object.prototype.toString.call(item1);
            let type2 = Object.prototype.toString.call(item2);

            if (type2 === '[object Undefined]') {
                diffs[key] = null;
                return;
            }

            if (type1 !== type2) {
                diffs[key] = item2;
                return;
            }

            if (type1 === '[object Object]') {
                let objDiff = ref.diff(item1, item2);
                if (Object.keys(objDiff).length > 0) {
                    diffs[key] = objDiff;
                }
                return;
            }

            if (type1 === '[object Array]') {
                if (!arraysMatch(item1, item2)) {
                    diffs[key] = item2;
                }
                return;
            }

            if (type1 === '[object Function]') {
                if (item1.toString() !== item2.toString()) {
                    diffs[key] = item2;
                }
            } else {
                if (item1 !== item2 ) {
                    diffs[key] = item2;
                }
            }

        };

        for (key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                compare(obj1[key], obj2[key], key, this);
            }
        }

        for (key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (!obj1[key] && obj1[key] !== obj2[key] ) {
                    diffs[key] = obj2[key];
                }
            }
        }

        return diffs;

    };

    fetchItems(filters, onlyreturn = false) {
        let used_filters = filters;
        let url = new URL(hostname + database.API_PATH + database.ENDPOINT_UNFILTERED);
        let filter_history = cookies.get('filter_history');
        let filter_blacklist = cookies.get('filter_blacklist');

        /* Counting function for objects */
        Object.size = function(obj) {
            let size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };

        if(filter_blacklist !== undefined) filter_blacklist.forEach(item => {
           used_filters[item] = undefined;
           //console.log("Ignored filter... ", item);
        });

        // fetching filtered items via GET
        if(Object.size(used_filters) > 2) {
            Object.keys(used_filters).forEach(index => {
                //console.log("Using filter... ", used_filters[index], used_filters[index] !== null && used_filters[index] !== '' && used_filters[index] !== undefined);
                if (used_filters[index] !== null && used_filters[index] !== '' && used_filters[index] !== undefined) {
                    url.searchParams.append(index, used_filters[index]);
                }
            });

            fetch(url, {
                    method: 'GET',
                    credentials: 'omit'
                })
                .then(res => res.json())
                .then(json => {
                    if (filter_history !== used_filters) cookies.set('filter_history', used_filters);
                    //console.log(this.state.round);

                    if (Object.size(json) < maxRounds/5) {

                        //console.error(`Could not filter enough items (${Object.size(json)})... Continue using previous items instead.`);

                        if ((filter_history !== used_filters) && (this.state.round < 4)) {

                            // A new filter was used for this round
                            let difference = this.diff(used_filters, filter_history);

                            for (const [key, value] of Object.entries(difference)) {

                                if (value !== null && value !== '' && value !== undefined) {

                                    if ((Object.prototype.toString.call(value) === '[object Array]')) {

                                        if (value.length !== 0) {

                                            this.blackListFilter(key);
                                            //console.log(`Blacklisted ${key}`);
                                        }
                                    }
                                    else {
                                        this.blackListFilter(key);
                                        //console.log(`Blacklisted ${key}`);
                                    }
                                }
                            }

                            this.setState({round: parseInt(this.state.round) + 1});
                        }
                    } else {

                        let resultCount = Object.size(json);
                        let differenceToPreference = Math.abs(startPreference - resultCount);

                        if(onlyreturn) {
                            return json;
                        } else {
                            this.setState({
                                items: json,
                                card_1: this.state.card_1,
                                card_2: this.state.card_2,
                                round: parseInt(this.state.round) + 1,
                                filters: used_filters,
                                differencePref: differenceToPreference
                            }, () => console.log('Filtered items: ', this.state.items));
                        }
                    }
                })
                .catch(err => console.warn(err));


        } else {

            /* Fetching items unfiltered */
            fetch(url, {credentials: 'omit'})
                .then(res => res.json())
                .then(json => {
                    if(onlyreturn) {
                        return json;
                    } else {
                        this.setState({
                            items: json,
                            card_1: this.state.card_1,
                            card_2: this.state.card_2,
                            round: parseInt(this.state.round) + 1,
                            filters: used_filters
                        }, () => console.log('Unfiltered items: ', this.state.items));
                    }
                })
                .catch(err => console.log(err));


        }
    }

    /* Privacy & Imprint */
    showImprint(event) {
        event.preventDefault();
        let url = new URL(hostname + database.API_PATH + database.ENDPOINT_IMPRINT);

        fetch(url, {
            method: 'GET',
            credentials: 'omit'
        })
        .then(res => res.text())
        .then(html => {
            let settingsListClassList = document.getElementById('settings-list').classList;

            if (settingsListClassList !== null) {
                settingsListClassList.add('-hidden');
                document.getElementById('settings-body').innerHTML = html;
                document.getElementById('settings-title').innerText = 'Impressum';
            }
        });
    }

    showPrivacy(event) {
        event.preventDefault();
        let url = new URL(hostname + database.API_PATH + database.ENDPOINT_PRIVACY);

        fetch(url, {
            method: 'GET',
            credentials: 'omit'
        })
            .then(res => res.text())
            .then(html => {

                let settingsListClassList = document.getElementById('settings-list').classList;

                if (settingsListClassList !== null) {

                    settingsListClassList.add('-hidden');
                    document.getElementById('settings-body').innerHTML = html;
                    document.getElementById('settings-title').innerText = 'Datenschutz';
                }
            });
    }

    /* Back Navigation */
    onBackButtonEvent = (e) => {
        e.preventDefault();

        if (this.state.showQuestionModal) {
            this.handleCloseQuestionModal();
            window.history.pushState(null, null, window.location.pathname);
        }
        else if (this.state.showPinModal) {
            this.handleClosePinModal();
            window.history.pushState(null, null, window.location.pathname);
        }
        else if (this.state.showHintModal) {
            this.handleCloseHintModal();
            window.history.pushState(null, null, window.location.pathname);
        }
        else if (this.state.showSettingsModal) {
            this.handleCloseSettingsModal();
            window.history.pushState(null, null, window.location.pathname);
        }
        else {
            this.doUndo();
            window.history.pushState(null, null, window.location.pathname);
        }
    }

    /* Multi Step */

    getStartStep() {
        if(this.state.showSpecify !== 0 || this.state.isFirstLoad == false) return 1; else return 0;
    }

    showHeader(step) {
        this.setState({header: step_header_map[step]});
    }

    showSpecifyBar() {
        this.setState({showSpecify: 1});
    }

    render() {
        let hideLogo;
        if (this.state.showSpecify === 0) {
            hideLogo = '';
        }
        else {
            hideLogo = '-hidden';
        }

        return (
            <>

                <div className="row header-logo-container">
                    <img src={coloredLogo} className="header-logo" alt="Logo"/>
                </div>

                <ProgressBar
                    value={this.state.progressVal}
                />

                <Hint show={this.state.showHintModal} onClick={this.handleCloseHintModal}/>

                <Navigation
                    undo={this.doUndo}
                    showQuestionModal={this.handleShowQuestionModal}
                    showPinModal={this.handleShowPinModal}
                    showSettingsModal={this.handleShowSettingsModal}
                    showLoader={this.state.showPinListLoader}
                    pinnedCount={this.countPinned()}
                />

                <Confetti
                    id={"canvas_confetti"}
                    run={this.state.runEffect}
                    gravity={0.2}
                    recycle={true}
                    opacity={this.state.effectOpacity}
                />

                <CookieBanner
                    message={"Um dir ein optimales Erlebnis zu bieten, verwenden wir Technologien wie Cookies, um Geräteinformationen zu speichern und/oder darauf zuzugreifen. Wenn du diesen Technologien zustimmst, können wir Daten wie das Surfverhalten oder eindeutige IDs auf dieser Website verarbeiten. Wenn du deine Zustimmung nicht erteilst oder zurückziehst, können bestimmte Merkmale und Funktionen beeinträchtigt werden."}
                    wholeDomain={true}
                    onDecline={this.toggleBackDrop()}
                    onAccept={this.toggleBackDrop()}
                    onAcceptPreferences={this.toggleBackDrop()}
                    onAcceptMarketing={() => {
                        this.toggleBackDrop();
                        window.dataLayer.push({ 'event': 'consent_marketing' });
                        this.state.marketingTracking = true;
                    }}
                    onAcceptStatistics={() => {
                        this.toggleBackDrop();
                        this.state.statisticsTracking = true;
                        ReactGA.initialize('G-MTHPBSP1KE');
                        ReactGA.set({ anonymizeIp: true });
                        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
                    }}
                    onDeclineStatistics={() => {
                        this.toggleBackDrop();
                        this.state.statisticsTracking = false;
                        cookies.remove('_ga', { path: '/', domain: document.domain });
                        cookies.remove('_ga_MTHPBSP1KE', { path: '/', domain: document.domain });
                    }}
                    onDeclineMarketing={() => {
                        this.toggleBackDrop();
                        this.state.marketingTracking = false;
                        // TODO: add trigger for blocking (GTM)
                    }}
                    policyLink="https://presentfindr.com/datenschutz"
                    imprintLink="https://presentfindr.com/impressum"
                    privacyPolicyLinkText="Datenschutzerklärung"
                    imprintLinkText="Impressum"
                    necessaryOptionText="Essenziell"
                    marketingOptionText="Marketing"
                    statisticsOptionText="Statistik"
                    acceptButtonText="Alle erlauben"
                    savePreferencesButtonText="Einstellungen speichern"
                    managePreferencesButtonText="Einstellungen anpassen"
                    showDeclineButton={true}
                    showPreferencesOption={false}
                    showStatisticsOption={true}
                    showMarketingOption={true}
                    showBanner={this.state.showBanner}
                    statisticsDescription={"Die technische Speicherung oder der Zugriff, der ausschließlich zu statistischen Zwecken erfolgt."}
                    necessaryDescription={"Die technische Speicherung oder der Zugang ist unbedingt erforderlich für den rechtmäßigen Zweck, die Nutzung eines bestimmten Dienstes zu ermöglichen, der vom Teilnehmer oder Nutzer ausdrücklich gewünscht wird, oder für den alleinigen Zweck, die Übertragung einer Nachricht über ein elektronisches Kommunikationsnetz durchzuführen."}
                />

                <div role="main" className="centered-content container">

                    <div className="landscape-banner">
                        Presentfindr unterst&uuml;tzt zum gegenw&auml;rtigen Zeitpunkt keine Landscape-Ansicht
                    </div>

                    <div className="showdown-cards">

                        <div className="card-row row">

                                <Card
                                    mounted={this._isMounted}
                                    otherItem={this.state.card_2}
                                    currentRound={this.state.round}
                                    currentProgress={this.state.progressVal}
                                    updatePinned={this.updatePinned}
                                    maxRounds={maxRounds}
                                    className={`zoom card col-lg-5 offset-lg-1`}
                                    itemid={this.state.card_1.item_id}
                                    onClick={this.handleCardClick}
                                    name="card_1"
                                    switchWith="card_2"
                                    imgurl={this.state.card_1.item_thumbnail}
                                    url={this.state.card_1.item_url}
                                    title={this.state.card_1.item_name}
                                    price={this.state.card_1.item_price}
                                    description={this.state.card_1.item_description}
                                    showResultModal={this.handleShowResultModal}
                                    hideResultModal={this.handleCloseResultModal}
                                    marketingTracking={this.state.marketingTracking}
                                    statisticsTracking={this.state.statisticsTracking}
                                />

                                <Card
                                    mounted={this._isMounted}
                                    otherItem={this.state.card_1}
                                    currentRound={this.state.round}
                                    currentProgress={this.state.progressVal}
                                    updatePinned={this.updatePinned}
                                    maxRounds={maxRounds}
                                    className={`zoom card col-lg-5`}
                                    itemid={this.state.card_2.item_id}
                                    onClick={this.handleCardClick}
                                    name="card_2"
                                    switchWith="card_1"
                                    imgurl={this.state.card_2.item_thumbnail}
                                    url={this.state.card_2.item_url}
                                    title={this.state.card_2.item_name}
                                    price={this.state.card_2.item_price}
                                    description={this.state.card_2.item_description}
                                    showResultModal={this.handleShowResultModal}
                                    hideResultModal={this.handleCloseResultModal}
                                    marketingTracking={this.state.marketingTracking}
                                    statisticsTracking={this.state.statisticsTracking}
                                />

                        </div>

                    </div>

                    <Modal show={this.state.showQuestionModal} onHide={this.handleCloseQuestionModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{(this.state.header !== undefined
                                && this.state.header !== null) ? this.state.header :
                                ((this.state.isFirstLoad == true) ? HEADER_DEFAULT : HEADER_EVENT)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <MultiStep
                                fetchHandler={this.fetchItems}
                                startAtStep={this.getStartStep()}
                                closeFunction={this.handleCloseQuestionModal}
                                showIndicator={this.showIndicator}
                                showSpecify={this.showSpecifyBar}
                                showHeader={this.showHeader}
                                diffPref={this.state.differencePref}
                                marketingTracking={this.state.marketingTracking}
                                statisticsTracking={this.state.statisticsTracking}
                                unblacklist={this.removeFilterFromBlacklist}
                                editMode={this.state.editFilter}
                                showWelcome={this.state.isFirstLoad}
                            />

                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showResultModal} onHide={this.handleCloseResultModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Jetzt schnell kaufen!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="welcome-msg">

                            <h3>{this.state.lastItem.item_name}</h3>
                            <br/><br/>
                            <img
                                className="card-img embed-responsive-item"
                                onError={(e) => {return e.target.src = altImg;}}
                                src={this.state.lastItem.item_thumbnail}
                                alt={this.state.lastItem.item_name}
                                draggable={false}
                            />
                            <br/><br/>
                            {this.state.lastItem.item_description}
                            <br/>
                            {this.state.lastItem.item_price}
                            <br/><br/>
                            <Button
                                variant="outline-secondary"
                                onClick={this.handleCloseResultModal}
                                size="xl"
                                className="card-footer-btn result-btn"
                            >
                                weiter stöbern
                            </Button>

                            <Button
                                variant="primary"
                                onClick={() => {window.open(this.state.lastItem.item_url);}}
                                size="xl"
                                className="card-footer-btn result-btn"
                            >
                                <FontAwesomeIcon icon={faLink} size="lg" className="card-footer-icon"/> zum Produkt
                            </Button>

                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showPinModal} onHide={this.handleClosePinModal}>
                        <Modal.Header closeButton>
                            <Modal.Title><h2>Deine gemerkten Geschenke</h2></Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="welcome-msg pin-modal">
                                {this.state.pinnedItems}
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showSettingsModal} onHide={this.handleCloseSettingsModal}>
                        <Modal.Header closeButton>
                            <Modal.Title id="settings-title">Einstellungen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body id="settings-body" className="settings-modal">
                            <ul id="settings-list" className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link" href="#" onClick={this.removeConsentGiven}>Cookie-Einstellungen</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" onClick={(event) => {this.showPrivacy(event)}}>Datenschutzerklärung</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" onClick={(event) => {this.showImprint(event)}}>Impressum</a>
                                </li>
                            </ul>
                        </Modal.Body>
                    </Modal>

                </div>
            </>
        );
    }
}

export default App;